<template>
  <section class="career-form">
    <div class="container">
      <form class="career-form-container" v-on:submit.prevent="onSubmit">
        <h5>Personal information</h5>
        <div class="perosnal-info">
          <div class="form-group profile-img col-md-6">
            <div class="img-container">
              <img ref="profile" src="#" alt="image" width="170" height="170" />
            </div>
            <!-- <i class="fas fa-camera-alt"></i>
            <input type="file" class="custom-file-input" accept=".jpg"> -->
            <label for="file" class="">
              <input class="img-input" required @change="onProfileChange($event)" type="file" id="file" accept="image/png, image/jpg" />
              <i class="fas fa-camera-alt"></i>
              Add profile picture
            </label>
          </div>
          <div class="form-group col-md-6">
            <label for="name">Name</label>
            <input v-model="jobForm.name" required maxlength="50" type="text" class="form-control" id="name" placeholder="Name" />
          </div>
          <div class="form-group col-md-6">
            <label for="exampleFormControlInput1">Email</label>
            <input v-model="jobForm.email" required type="email" class="form-control" id="email" placeholder="Email" />
          </div>
          <div class="form-group col-md-6 ">
            <label for="phoneNumber">Phone Number</label>
            <div class="d-flex phone-container">
              <select v-model="jobForm.phoneCode" class="form-control" name="countryCode" id="">
                <option value="+92" Selected>+92</option>
              </select>
              <input v-model="jobForm.phoneNumber" required maxlength="11" type="tel" oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');" class="form-control " id="phoneNumber" placeholder="Phone Number" />
            </div>
          </div>
          <div class="form-group col-md-6">
            <label for="dob">Date Of Birth</label>
            <input v-model="jobForm.dob" required class="form-control" type="date" id="dob" name="dob" />
          </div>
          <div class="col-md-6">
            <div class=""><label class="form-check-label" for="gender">Gender</label></div>

            <div class="form-check form-check-inline">
              <input v-model="jobForm.gender" class="form-check-input" type="radio" name="gender" id="male" value="male" checked />
              <label class="form-check-label" for="male">Male</label>
            </div>
            <div class="form-check form-check-inline mb-3">
              <input v-model="jobForm.gender" class="form-check-input" type="radio" name="gender" id="female" value="female" />
              <label class="form-check-label" for="female">Female</label>
            </div>
          </div>
        </div>
        <div class="profile-info">
          <h5>Profile</h5>
          <div class="row">
            <div class="form-group col-md-6">
              <label for="Subject">Subject</label>
              <select v-model="jobForm.subject" required id="Subject" class="form-control">
                <option value="Physics" selected>Physics</option>
                <option value="Chemistry">Chemistry</option>
                <option value="Biology">Biology</option>
              </select>
            </div>
            <div class="form-group col-md-6">
              <label for="level">Level</label>
              <select v-model="jobForm.level" required id="level" class="form-control">
                <option value="Primary" selected>Primary</option>
                <option value="Matric">Matric</option>
                <option value="Inter">Inter</option>
              </select>
            </div>
          </div>
          <div class="row">
            <div class="form-group col-md-6">
              <label for="experience">Experience</label>
              <select v-model="jobForm.experience" required class="form-control" id="experience">
                <option v-for="years in experience" :key="years" :value="years">{{ years }}</option>
              </select>
            </div>
            <div class="col-md-6 shift-container">
              <label class="form-check-label" for="shift">Shift</label>
              <div>
                <div class="form-check form-check-inline">
                  <input v-model="jobForm.shift" class="form-check-input" type="radio" name="shift" id="partTime" value="partTime" checked />
                  <label class="form-check-label" for="partTime">Part Time</label>
                </div>
                <div class="form-check form-check-inline">
                  <input v-model="jobForm.shift" class="form-check-input" type="radio" name="shift" id="fullTime" value="FullTime" />
                  <label class="form-check-label" for="fullTime">Full Time</label>
                </div>
              </div>
            </div>
          </div>
          <div class="text-center mb-2"><label class="form-check-label">Qualification</label></div>
          <div class="row">
            <div class="form-group col-md-6">
              <label for="degree">Last Degree</label>
              <select v-model="jobForm.degree" required id="degree" class="form-control">
                <option value="Bachelor" selected>Bachelor</option>
                <option value="Master">Master</option>
                <option value="M.Phil">M.Phil</option>
                <option value="PhD">PhD</option>
              </select>
            </div>
            <div class="form-group col-md-6">
              <label for="years">Passing Year</label>
              <select v-model="jobForm.year" required class="form-control" id="years">
                <option v-for="year in years" :key="year" :value="year">{{ year }}</option>
              </select>
            </div>
          </div>
          <label>Resume</label>
          <div class="file-container form-group">
            <label for="resumeControl" class="resume-file-control">
              <input @change="onFileChange($event)" required type="file" id="resumeControl" accept=".pdf" name="fileControl" class="" />
              <span class="file-btn">Upload a file</span>
              or drag and drop here
            </label>
            <span class="block truncate mt-4">{{ selectedFile }}</span>
            <!-- <p class="text-sm pl-2">Attach Resume</p> -->
          </div>
          <label>Video</label>
          <div class="file-container form-group">
            <label for="videoControl" class="resume-file-control">
              <input required @change="onVideoChange($event)" type="file" accept=".mp4" id="videoControl" />
              <span class="file-btn">Attach Video</span>
              or drag and drop here
            </label>
            <span class="block truncate mt-4">{{ selectedVideo }}</span>
            <!-- <p class="text-sm pl-2">Attach Resume</p> -->
          </div>

          <!-- <label for="fileControl" class="">
            <input required @change="onVideoChange($event)" type="file" accept=".mp4" />
            Attach Video
          </label> -->
        </div>

        <button type="submit" class="btn btn-primary">Submit</button>
      </form>
    </div>
  </section>
</template>

<script>
export default {
  data() {
    return {
      years: [],
      experience: [],
      selectedFile: "",
      selectedVideo: "",
      selectedImage: "",
      jobForm: {
        name: "",
        email: "",
        phoneCode: "+92",
        phoneNumber: "",
        gender: "male",
        dob: "",
        subject: "Physics",
        level: "Primary",
        shift: "partTime",
        degree: "Bachelor",
        year: "2020",
        experience: "0",
        file: "",
        video: "",
        profileImage: "",
      },
    };
  },
  mounted() {
    this.years = this.range(2020, 1970, -1);
    this.experience = this.range(0, 20, 1);
  },
  //    watch: {
  //     thisprofileImage() {
  //       this.previewImage();
  //     },
  //   },
  methods: {
    onSubmit() {
      console.log(this.jobForm);
    },
    range(start, stop, step) {
      return Array.from({ length: (stop - start) / step + 1 }, (_, i) => start + i * step);
    },

    onFileChange(e) {
      this.selectedFile = e.target.files[0].name;
      this.jobForm.file = e.target.files[0];
    },
    onProfileChange(e) {
      this.selectedImage = e.target.files[0].name;
      this.jobForm.profileImage = e.target.files[0];
      var reader = new FileReader();
      reader.onloadend = () => {
        const uploaded_image = reader.result;
        this.$refs.profile.setAttribute("src", uploaded_image);
      };
      reader.readAsDataURL(this.jobForm.profileImage);
    },
    onVideoChange(e) {
      this.selectedVideo = e.target.files[0].name;
      this.jobForm.video = e.target.files[0];
    },
    // previewImage: function () {
    //   const allowedTypes = ["image/jpeg", "image/jpg", "image/png"];

    //   // Ensure that you have a file before attempting to read it
    //   if (this.profileImage) {
    //     if (!allowedTypes.includes(this.file.type)) {
    //       this.$alertError(this.$getAppMessage(applicationMessageEnum.Key.File_Format_Error, [allowedTypes.join()]));
    //       return;
    //     }
    //     //if file size greater than 1 MB then show error
    //     if (this.profileImage.size / 1024 / 1024 > 1) {
    //       this.$alertError(this.$getAppMessage(applicationMessageEnum.Key.File_Size_Error, [1]));
    //       return;
    //     }

    //     // create a new FileReader to read this image and convert to base64 format
    //     var reader = new FileReader();
    //     // Define a callback function to run, when FileReader finishes its job
    //     reader.onload = (e) => {
    //       // Note: arrow function used here, so that "this" refers to the Vue component
    //       // Read image as base64 and bind to croppie
    //       console.log(e.target.result);
    //     };
    //     // Start the reader job - read file as a data url (base64 format)
    //     reader.readAsDataURL(this.file);
    //   }
    // },
  },
};
</script>
